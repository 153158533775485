<template>
  <component
    v-if="mutableContent"
    :is="dynamicComponentName"
    :contentable="mutableContent"
  >
  </component>
  <no-content v-else />
</template>

<script>
import { contentHasFinished } from "@/modules/main/pages/course/store/helpers";
import ReadingPlay from "@/modules/main/pages/course/components/readingPlay";
import NoContent from "@/modules/main/pages/course/components/no-content";
import VideoPlay from "@/modules/main/pages/course/components/videoPlay";
import QuizPlay from "@/modules/main/pages/course/components/quizPlay";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { ref, watch, nextTick } from "vue";

const { useGetters } = createNamespacedHelpers("course");

export default {
  name: "CourseContent",

  components: { NoContent, QuizPlay, ReadingPlay, VideoPlay },

  setup() {
    const { content } = useGetters({
        content: "getCourseOpened",
      }),
      dynamicComponentName = ref(null),
      mutableContent = ref(null);

    const setDynamicComponent = () => {
      if (content.value) {
        mutableContent.value = content.value.contentable;
        mutableContent.value.isFinished = contentHasFinished(content.value);

        let type = content.value.contentable_type.toLowerCase();
        if (type.match("questionary")) {
          dynamicComponentName.value = "quiz-play";
          mutableContent.value = {
            questionnaire_id: content.value.contentable_id,
            content,
          };
        } else if (type.match("text")) {
          dynamicComponentName.value = "reading-play";
        } else if (type.match("video")) {
          dynamicComponentName.value = "video-play";
        }
      } else {
        mutableContent.value = null;
      }
    };

    setDynamicComponent();

    watch(content, () => {
      dynamicComponentName.value = null;
      nextTick(() => {
        setDynamicComponent();
      });
    });

    return {
      content,
      mutableContent,
      dynamicComponentName,
    };
  },
};
</script>
