<template>
  <div class="column">
    <div class="reading-play">
      <div class="content">
        <p class="title" v-if="contentable.name">{{ contentable.name }}</p>
        <div v-html="contentable.text"></div>
      </div>
    </div>
    <next-course-button v-if="contentable.isFinished" class="finish-button" />
    <finish-button v-else class="finish-button" />
  </div>
</template>

<script>
import NextCourseButton from "@/modules/main/pages/course/components/nextCourseButton";
import FinishButton from "@/modules/main/pages/course/components/finishButton";

export default {
  name: "ReadingPlay",

  components: { NextCourseButton, FinishButton },

  props: {
    contentable: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.reading-play {
  width: 100%;
  .content {
    .title {
      font-size: 1.5em;
      font-weight: bolder;
      color: #bababa;
    }
    padding: 5% 15%;
    background: white;
    max-height: 76vh;
    overflow: auto;
  }
}
</style>
