<template>
  <q-dialog v-model="showMessage" persistent>
    <q-card>
      <q-card-section class="row items-center no-wrap">
        <q-avatar icon="check_circle" color="positive" text-color="white" />
        <span class="q-ml-sm text-weight-medium text-grey-8">
          {{ $t("course.finish_message.congratulations") }}<br />
        </span>
      </q-card-section>
      <q-card-section>
        <span class="text-weight-medium text-grey-8">
          {{ $t("course.finish_message.now_u_can") }}
        </span>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          flat
          color="dark"
          :label="$t('course.finish_message.my_courses')"
          :to="{ name: 'courses', query: { tab: 'finished' } }"
          v-close-popup
        />
        <q-btn
          color="dark"
          :label="$t('course.view_certificate')"
          :to="{ name: 'course.certificate', params: { slug: course.slug } }"
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { nextTick, ref, watch } from "vue";

const { useGetters } = createNamespacedHelpers("course");

export default {
  name: "finishMessage",

  props: {
    course: {
      type: Object,
      require: true,
    },
  },

  setup() {
    const showMessage = ref(false),
      { finished } = useGetters({
        finished: "isCourseFinished",
      });

    watch(finished, () => {
      nextTick(() => {
        if (finished.value) showMessage.value = true;
      });
    });

    return {
      showMessage,
    };
  },
};
</script>
