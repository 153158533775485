<template>
  <q-scroll-area
    style="height: 76vh; max-width: 300px;"
    :thumb-style="thumbStyle"
    :content-style="contentStyle"
    :content-active-style="contentActiveStyle"
  >
    <div class="c-sections" style="max-width: 400px">
      <q-expansion-item
        dense
        group="course_sections"
        :label="section.description"
        :key="`section-key${section.id}`"
        v-for="section in builtSections()"
        :default-opened="section.defaultOpened"
      >
        <course-control-item
          v-for="content in section.contents"
          :key="`content-key${content.id}`"
          :content="content"
        />
      </q-expansion-item>
    </div>
  </q-scroll-area>
</template>

<script>
import CourseControlItem from "@/modules/main/pages/course/components/courseControlItem";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { pickBy, orderBy } from "lodash";

const { useActions } = createNamespacedHelpers("course");

export default {
  name: "CourseControl",

  components: { CourseControlItem },

  props: {
    sections: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    let itemOpened = null;

    const { setOpenedCourse } = useActions(["setOpenedCourse"]);

    const builtSections = () => {
      let items = orderBy(
        pickBy(
          props.sections,
          (s) => s.is_active === 1 && s.contents && s.contents.length
        ),
        "sequence"
      );
      items.forEach((item, key) => {
        if (itemOpened) return;

        let school_class = null;
        item.contents.forEach((content) => {
          if (school_class !== null) return;
          if (content.schoolclass && content.schoolclass.state) {
            if (content.schoolclass.state.status < 100) school_class = content;
          } else if (
            !content.schoolclass ||
            content.schoolclass.finished_at === null
          ) {
            school_class = content;
          }
        });
        if (school_class) {
          items[key].defaultOpened = true;
          itemOpened = item;
          setOpenedCourse(school_class);
        }
      });
      return items;
    };

    return {
      builtSections,
      contentStyle: {
        backgroundColor: "rgba(0,0,0,0.02)",
        color: "#555",
      },

      contentActiveStyle: {
        backgroundColor: "#eee",
        color: "black",
      },

      thumbStyle: {
        right: "2px",
        borderRadius: "5px",
        backgroundColor: "#575757",
        width: "5px",
        opacity: "0.75",
      },
    };
  },
};
</script>

<style lang="scss">
.c-sections {
  background: #f7f7f794;
  .q-item {
    box-shadow: 1px 1px 4px 1px #c7c7c7;
    margin-bottom: 5px;
    padding: 10px;
  }
  .c-section-content {
    margin-right: 10px;
    padding: 15px 10px;
    border-bottom: 1px solid #bcbcbc;
  }
}
</style>
