<template>
  <div class="row justify-center">
    <q-btn
      class="q-ma-sm default-rounded text-weight-regular"
      unelevated
      color="dark"
      :label="$t('course.class.next')"
      @click="onButtonClick"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { notifyError } from "@/shared/helpers/notify";

const { useActions } = createNamespacedHelpers("course");

export default {
  name: "NextCourseButton",

  setup() {
    const { nextCourseAction } = useActions(["nextCourseAction"]);

    return {
      onButtonClick() {
        nextCourseAction().catch(() => {
          notifyError();
        });
      },
    };
  },
};
</script>
<style lang="scss"></style>
