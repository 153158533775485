<template>
  <div class="column">
    <div class="quiz-play" v-if="questionnaire">
      <div class="content">
        <p class="title">{{ questionnaire.title }}</p>
        <question-option
          v-for="question in questionnaire.questions"
          :key="question.id"
          class="q-mb-lg"
          :question="question"
          :answer="answer(question.id)"
          :st_answer="st_answer(question.id)"
          @updated-question="pushQuestionToForm"
        />
      </div>
    </div>
    <div class="row justify-center full-width" v-if="!hasFinished">
      <q-btn
        v-if="summary('status', 'success')"
        class="q-ma-sm default-rounded text-weight-regular"
        unelevated
        color="primary"
        label="Salvar questionário"
        :loading="inRequest"
        @click="saveQuestion"
      />
      <q-btn
        v-else
        class="q-ma-sm default-rounded text-weight-regular"
        unelevated
        color="dark"
        label="Enviar questionário"
        :loading="inRequest"
        :disable="!canFinish"
        @click="submitQuestion"
      />
    </div>
    <next-course-button v-else class="finish-button" />
    <q-dialog v-model="showMessage" persistent>
      <q-card>
        <q-card-section
          class="row items-center no-wrap"
          v-if="summary('status', 'success')"
        >
          <q-avatar icon="check_circle" color="positive" text-color="white" />
          <span class="q-ml-sm text-weight-medium text-grey-8">
            {{ $t("course.quiz.congratulations") }}<br />
            {{ $t("course.quiz.message_1") }}
          </span>
        </q-card-section>
        <q-card-section v-if="summary('status', 'success')">
          <span class="text-weight-medium text-grey-8">
            {{ $t("course.quiz.message_2") }}
          </span>
        </q-card-section>
        <q-card-section
          class="row items-center no-wrap"
          v-if="summary('status', 'failed')"
        >
          <q-avatar icon="rule" color="negative" text-color="white" />
          <span class="q-ml-sm text-weight-medium text-grey-8">
            {{ $t("course.quiz.message_3") }}
          </span>
        </q-card-section>
        <q-card-section>
          <div class="row no-wrap text-grey-7 q-mt-md">
            <q-icon
              class="col-1 self-center q-mr-sm"
              name="draw"
              size="1.5em"
            />
            <div
              class="col-11"
              v-html="
                $t('course.quiz.min_points', {
                  value: summary('minimun_score'),
                })
              "
            ></div>
          </div>
          <div class="row no-wrap text-grey-7 q-mt-md">
            <q-icon
              class="col-1 self-center q-mr-sm"
              name="edit"
              size="1.5em"
            />
            <div
              class="col-11"
              v-html="
                $t('course.quiz.right_answers', {
                  value: summary('right'),
                })
              "
            ></div>
          </div>
          <div class="row no-wrap text-grey-7 q-mt-md">
            <q-icon
              class="col-1 self-center q-mr-sm"
              name="dangerous"
              size="1.5em"
            />
            <div
              class="col-11"
              v-html="
                $t('course.quiz.wrong_answers', {
                  value: summary('wrong'),
                })
              "
            ></div>
          </div>
          <div class="row no-wrap text-grey-7 q-mt-md">
            <q-icon
              class="col-1 self-center q-mr-sm"
              name="dangerous"
              size="1.5em"
            />
            <div
              class="col-11"
              v-html="
                $t('course.quiz.total_answers', {
                  value: summary('score'),
                })
              "
            ></div>
          </div>
        </q-card-section>
        <q-card-actions align="right" v-if="summary('status', 'failed')">
          <q-btn
            flat
            :label="$t('course.quiz.ok')"
            color="dark"
            v-close-popup
          />
        </q-card-actions>
        <q-card-actions align="right" v-if="summary('status', 'success')">
          <q-btn
            flat
            :label="$t('course.quiz.review')"
            color="dark"
            v-close-popup
          />
          <q-btn
            flat
            :label="$t('course.quiz.save')"
            color="dark"
            @click="saveQuestion"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import {
  contentHasFinished,
  summaryScore,
} from "@/modules/main/pages/course/store/helpers";
import NextCourseButton from "@/modules/main/pages/course/components/nextCourseButton";
import QuestionOption from "@/modules/main/pages/course/components/questionOption";
import { notifyError, notifySuccess } from "@/shared/helpers/notify";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { filter, find, orderBy, pickBy, get } from "lodash";
import { ref } from "vue";

const { useActions } = createNamespacedHelpers("course");

export default {
  name: "QuizPlay",

  components: { NextCourseButton, QuestionOption },

  props: {
    contentable: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const questionnaire = ref(null),
      data_form = ref([]),
      canFinish = ref(false),
      inRequest = ref(false),
      showMessage = ref(false),
      scoreQuestions = ref(null),
      hasFinished = ref(false);

    if (
      get(props.contentable, "content.schoolclass.student_answers", []).length >
        0 ||
      contentHasFinished(props.contentable.content)
    ) {
      hasFinished.value = true;
    }

    const checkIfCanFinish = () => {
        canFinish.value =
          questionnaire.value.questions.length ===
          filter(data_form.value, (q) => !!q.answer_id).length;
      },
      pushQuestionToForm = (question) => {
        let newData = filter(
          data_form.value,
          (q) => q.question_id !== question.question_id
        );
        newData.push(question);
        data_form.value = newData;
        checkIfCanFinish();
      };

    const {
      fetchQuestionnaireItems,
      classScoreTestAction,
      classScoreSaveAction,
      nextCourseAction,
      setOpenedCourseSchoolClass,
      setSchoolClassToCourseContent,
    } = useActions([
      "fetchQuestionnaireItems",
      "classScoreTestAction",
      "classScoreSaveAction",
      "nextCourseAction",
      "setOpenedCourseSchoolClass",
      "setSchoolClassToCourseContent",
    ]);

    fetchQuestionnaireItems({
      questionnaire_id: props.contentable.questionnaire_id,
    }).then((data) => {
      data.questions = orderBy(
        pickBy(data.questions, (q) => q.active === 1),
        "sequence"
      );
      questionnaire.value = data;
    });

    const submitQuestion = () => {
      inRequest.value = true;
      classScoreTestAction(data_form.value)
        .then((score) => {
          scoreQuestions.value = score;
          showMessage.value = true;
        })
        .catch((reason) => {
          notifyError();
          if (reason.errors) Object.values(reason.errors).forEach(notifyError);
        })
        .finally(() => (inRequest.value = false));
    };

    const getClassFromSaveAction = (data) => {
        return {
          finished_at: true,
          content_id: props.contentable.content.id,
          student_answers: get(data, "questions", []).map((q) => ({
            answer: q.answer_id.join("."),
            correct_answer: q.correct_answer_id.join("."),
            ...q,
          })),
        };
      },
      saveQuestion = () => {
        inRequest.value = true;
        classScoreSaveAction(data_form.value)
          .then((data) => {
            hasFinished.value = true;
            Promise.all([
              setSchoolClassToCourseContent(getClassFromSaveAction(data)),
              setOpenedCourseSchoolClass(getClassFromSaveAction(data)),
            ]).then(() => {
              nextCourseAction()
                .then(() => notifySuccess())
                .catch(() => notifyError());
            });
          })
          .catch(({ errors }) => {
            notifyError();
            if (errors) Object.values(errors).forEach(notifyError);
          })
          .finally(() => (inRequest.value = false));
      };

    const summary = (py, compare = null) => {
      return summaryScore(scoreQuestions.value, py, compare);
    };

    const answer = (qt_id) => {
      if (summary("status", "success") && scoreQuestions.value.questions) {
        return find(
          scoreQuestions.value.questions,
          ({ question_id }) => question_id === qt_id
        );
      }
      return null;
    };

    const st_answer = (qt_id) =>
      find(
        get(props.contentable, "content.schoolclass.student_answers", []),
        ({ question_id }) => question_id === qt_id
      );

    return {
      questionnaire,
      canFinish,
      showMessage,
      scoreQuestions,
      hasFinished,
      inRequest,
      saveQuestion,
      pushQuestionToForm,
      summary,
      answer,
      st_answer,
      submitQuestion,
    };
  },
};
</script>
<style lang="scss">
.quiz-play {
  width: 100%;
  background: white;
  max-height: 76vh;
  overflow: auto;
  .content {
    .title {
      font-size: 1.5em;
      font-weight: bolder;
      color: #bababa;
    }
    margin: 10% 15%;
  }
}
</style>
