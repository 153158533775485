<template>
  <div class="content-custom-course" v-if="course">
    <div
      class="q-pa-sm full-width bg-white default-rounded q-mb-lg"
      :class="{
        'q-mt-md': $q.screen.width <= 800,
      }"
    >
      <div
        class="row"
        :class="{
          'justify-center': $q.screen.width <= 800,
          'no-wrap justify-between': $q.screen.width > 800,
        }"
      >
        <q-btn
          flat
          icon="reply"
          color="black-11"
          label="Meus Cursos"
          :to="{ name: 'courses', query: { tab: 'inProgress' } }"
        />
      </div>
    </div>
    <div class="row course-row">
      <course-control
        class="full-width course-control"
        :sections="course.sections"
      />
      <course-content class="full-width course-content" />
    </div>
    <finish-message :course="course" />
  </div>
</template>

<script>
import CourseControl from "@/modules/main/pages/course/components/courseControl";
import CourseContent from "@/modules/main/pages/course/components/courseContent";
import FinishMessage from "@/modules/main/pages/course/components/finishMessage";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { useRoute } from "vue-router";

const { useActions, useGetters } = createNamespacedHelpers("course");

export default {
  components: { FinishMessage, CourseContent, CourseControl },

  setup() {
    const route = useRoute(),
      { course } = useGetters({
        course: "getCourse",
      }),
      { fetchCourseItems } = useActions(["fetchCourseItems"]);

    const initCourse = () => {
      fetchCourseItems({
        enroll_id: route.params.id,
      }).then((data) => (course.value = data));
    };

    initCourse();

    return {
      course,
    };
  },
};
</script>

<style lang="scss">
.content-custom-course {
  margin: -25px -20px;
  .course-row {
    flex-wrap: nowrap;
    justify-content: center;
  }
}
@media only screen and (max-width: 800px) {
  .content-custom-course {
    .course-row {
      flex-wrap: wrap-reverse;
    }
    .course-content {
      margin-top: 10px;
      &.video-type {
        margin-bottom: 5em;
      }
    }
    .course-control {
      margin-top: 20px;
      width: 100% !important;
      max-width: unset !important;
      .c-sections {
        max-width: unset !important;
      }
    }
  }
}
</style>
