<template>
  <div class="q-pr-lg q-pl-lg">
    <div class="bg-dark text-grey-5 default-rounded full-width">
      <q-parallax>
        <template v-slot:content="scope">
          <div
            class="absolute column items-center"
            :style="{
              opacity: 0.45 + (1 - scope.percentScrolled) * 0.55,
              top: scope.percentScrolled * 60 + '%',
              left: 0,
              right: 0,
            }"
          >
            <div
              class="text-center text-weight-thin"
              :class="{
                'text-h4': $q.platform.is.desktop,
                'text-h5': $q.platform.is.mobile,
              }"
            >
              {{ $t("course.no_class_selected") }}
            </div>
          </div>
        </template>
      </q-parallax>
    </div>
  </div>
</template>
