<template>
  <div
    class="c-section-content row no-wrap cursor-pointer"
    @click="onCourseClick"
  >
    <div class="check self-baseline q-mr-sm">
      <q-checkbox dense color="green" :model-value="finished" disable />
    </div>
    <div class="column full-width">
      <p>{{ content.title }}</p>
      <div class="row justify-between">
        <span class="self-center">
          <q-icon :name="iconName" size="1.5em" class="q-mr-sm" />
          <strong>{{ content.duration }} min</strong>
        </span>
        <q-btn-dropdown
          flat
          outline
          unelevated
          icon="download"
          class="no-padding no-margin"
          v-if="content.attachments && content.attachments.length > 0"
        >
          <q-list>
            <q-item
              clickable
              v-close-popup
              :href="att.url"
              @click.prevent="onAttClick(att)"
              :key="`attachments-key${attIndex}`"
              v-for="(att, attIndex) in content.attachments"
            >
              <q-item-section>
                <q-item-label>{{ att.name }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { contentHasFinished } from "@/modules/main/pages/course/store/helpers";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { notifyError } from "@/shared/helpers/notify";
import { ref, watch, nextTick } from "vue";

const { useActions } = createNamespacedHelpers("course");

export default {
  name: "CourseContent",

  props: {
    content: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    watch(props.content, () => {
      nextTick(() => {
        finished.value = contentHasFinished(props.content);
      });
    });

    let isFinished = contentHasFinished(props.content);
    const { setOpenedCourse, fetchAttachment } = useActions([
        "setOpenedCourse",
        "fetchAttachment",
      ]),
      onCourseClick = () => {
        setOpenedCourse(props.content);
      };

    const downloadURI = (dataUrl, filename) => {
        var link = document.createElement("a");
        link.download = filename;
        link.target = "_blank";
        link.href = dataUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      onAttClick = (att) => {
        fetchAttachment({ id: att.id })
          .then((att) => {
            downloadURI(att, true);
          })
          .catch((reason) => {
            if (reason && reason.errors) {
              Object.values(reason.errors).forEach(notifyError);
            } else {
              notifyError();
            }
          });
      };

    const iconName = ref(null),
      finished = ref(isFinished);

    if (props.content.contentable_type.toLowerCase().match("questionary")) {
      iconName.value = "playlist_add";
    } else if (props.content.contentable_type.toLowerCase().match("text")) {
      iconName.value = "local_library";
    } else {
      iconName.value = "play_arrow";
    }

    return {
      iconName,
      finished,
      onAttClick,
      onCourseClick,
    };
  },
};
</script>
