<template>
  <div class="video-type" style="flex: 1">
    <div class="content-video">
      <iframe
        width="100%"
        height="100%"
        frameborder="0"
        :src="contentable.url"
        allow="autoplay; encrypted-media"
        allowfullscreen=""
      >
      </iframe>
    </div>
    <next-course-button v-if="contentable.isFinished" class="finish-button" />
    <finish-button v-else class="finish-button" />
  </div>
</template>

<script>
import NextCourseButton from "@/modules/main/pages/course/components/nextCourseButton";
import FinishButton from "@/modules/main/pages/course/components/finishButton";

export default {
  name: "VideoPlay",

  components: { NextCourseButton, FinishButton },

  props: {
    contentable: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.video-type {
  background: #2f3235;
  .content-video {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 10px;
  }
  .finish-button {
    background: #2f3235;
  }
}

@media only screen and (max-width: 450px) {
  .video-type {
    .content-video {
      width: 95%;
    }
  }
}
</style>
