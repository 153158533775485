<template>
  <div class="row justify-center">
    <q-btn
      unelevated
      color="dark"
      class="q-ma-md text-weight-regular"
      :label="$t('course.finish_buttom')"
      @click="onButtonClick"
    />
  </div>
</template>

<script>
import { notifyError, notifySuccess } from "@/shared/helpers/notify";
import { createNamespacedHelpers } from "vuex-composition-helpers";

const { useActions } = createNamespacedHelpers("course");

export default {
  name: "finishButton",

  setup() {
    const { finishClassAction } = useActions(["finishClassAction"]);

    return {
      onButtonClick() {
        finishClassAction()
          .then(() => notifySuccess())
          .catch(() => notifyError());
      },
    };
  },
};
</script>
