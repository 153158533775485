<template>
  <div class="column c-question">
    <div class="c-question-t row no-wrap">
      <span class="q-mr-sm text-weight-bold row no-wrap">
        <span>{{ question.sequence }}.</span>
        <q-icon
          v-if="isCorrect !== null"
          :name="isCorrect ? 'check_circle' : 'rule'"
          :color="isCorrect ? 'positive' : 'negative'"
          size="xs"
          class="q-ml-sm"
        ></q-icon>
      </span>
      <div v-html="question.question" />
    </div>
    <div class="row q-mt-sm q-mb-sm q-pa-sm q-pl-lg explanation bg-amber-1">
      <dip v-html="question.explanation"></dip>
    </div>

    <q-option-group
      v-if="options.length"
      :options="options"
      :type="question_type"
      :disable="disable"
      v-model="group"
      @update:model-value="unificationOptionSelected"
    >
      <template v-slot:label="opt">
        <div class="row items-center no-wrap">
          <span>{{ opt.label }}</span>
          <q-icon
            v-if="getAnswerIcon(opt) !== null"
            :name="getAnswerIcon(opt).name"
            :color="getAnswerIcon(opt).color"
            size="xs"
            class="q-ml-sm"
          ></q-icon>
        </div>
      </template>
    </q-option-group>
    <p v-else class="text-negative default-card-border q-mt-lg">
      {{ $t("course.no_answers_to_question") }}
    </p>
  </div>
</template>

<script>
import { orderBy, pickBy, map, find } from "lodash";
import { nextTick, ref, watch } from "vue";

export default {
  name: "QuestionOption",

  emits: ["updatedQuestion"],

  props: {
    question: {
      type: Object,
      required: true,
    },
    st_answer: {
      type: Object,
    },
    answer: {
      type: Object,
    },
  },

  setup(props, root) {
    const question_type = "checkbox",
      group = ref([]),
      disable = ref(false),
      isCorrect = ref(null),
      contentAnswer = ref(null);

    watch(
      () => props.answer,
      () => nextTick(() => checkAnswer())
    );

    const builtGroup = () => {
        if (props.st_answer) {
          disable.value = true;

          if (parseFloat(props.st_answer.score) > 0) isCorrect.value = true;
          else isCorrect.value = false;

          group.value = props.st_answer.answer
            .split(",")
            .map((id) => parseInt(id));
          contentAnswer.value = props.st_answer.correct_answer
            .split(",")
            .map((id) => parseInt(id));
        }
      },
      builtOptions = () => {
        let items = orderBy(
          pickBy(props.question.answer_options, (s) => s.active === 1),
          "sequence"
        );
        return map(items, (item) => ({
          label: item.answer,
          value: item.id,
          color: "black",
          iconName: "rule",
        }));
      },
      checkAnswer = () => {
        if (props.answer) {
          if (props.answer.status.toLowerCase() === "correct")
            isCorrect.value = true;
          else isCorrect.value = false;
        }
      },
      getAnswerIcon = ({ value }) => {
        let answers =
          (props.answer ? props.answer.correct_answer_id : null) ||
          contentAnswer.value;
        if (answers) {
          if (find(answers, (v) => v == value))
            return {
              name: "done",
              color: "positive",
            };
          else
            return {
              name: "dangerous",
              color: "negative",
            };
        }
        return null;
      },
      unificationOptionSelected = () => {
        nextTick(() => {
          root.emit("updatedQuestion", {
            question_id: props.question.id,
            answer_id: group.value || null,
          });
        });
      };

    const options = builtOptions();
    builtGroup();

    return {
      options,
      group,
      isCorrect,
      disable,
      question_type,
      getAnswerIcon,
      unificationOptionSelected,
    };
  },
};
</script>

<style lang="scss">
.c-question {
  .explanation {
    * {
      background: transparent !important;
      font-size: 0.99em !important;
    }
  }
  &.positive {
    .c-question-t {
      color: var(--q-positive);
      font-weight: 500;
      opacity: 0.7;
    }
  }
  &.negative {
    .c-question-t {
      color: var(--q-negative);
      font-weight: 500;
      opacity: 0.7;
    }
  }
}
</style>
